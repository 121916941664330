import { createLoader } from '@periodica/react-yandex-auth-button';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  type PropsWithChildren,
} from 'react';
import useEvent from 'use-event-callback';

import './index.css';

const USEDESK_SCRIPT_URL = 'https://lib.usedesk.ru/secure.usedesk.ru/widget_161244_31915.js';

type UsedeskMessenger = {
  open(type?: string): void;
  toggle(value?: boolean): void;
  close(): void;
  userIdentify(userInfo: { name?: string; email?: string; phone?: string; token?: string }): void;
  getChatToken(): string;
};

declare global {
  interface Window {
    usedeskMessenger: UsedeskMessenger;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __usedeskWidgetMessageInClosedWidgetCallback: () => void;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __widgetInitCallback: () => void;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __usedeskWidgetInitedCallback: () => void;
  }
}

function NullComponent() {
  return null;
}

const useWidget = createLoader(USEDESK_SCRIPT_URL, () => window.usedeskMessenger);

const createWidgetLoader = (
  onLoad: (widget: UsedeskMessenger) => void,
  onError: (error: Error) => void
) =>
  function WidgetLoader() {
    const widget = useWidget(onError);
    const onLoadRef = useEvent(onLoad);

    useEffect(() => {
      if (widget) {
        onLoadRef(widget);
      }
    }, [onLoadRef, widget]);

    return null;
  };

type UseDeskContext = {
  error: Error | undefined;
  messageCount: number;
  isReady: boolean;
  open: (type?: string) => void;
  load: () => void;
};

const context = createContext<UseDeskContext | null>(null);

export function UseDeskProvider({ children }: PropsWithChildren<{}>) {
  const optionRef = useRef<{
    openWhenReady?: string;
    isFirstMessageSkipped?: boolean;
    isInitializationRequired?: boolean;
  }>({
    isFirstMessageSkipped: false,
    isInitializationRequired: false,
  });
  const [widget, setWidget] = useState<UsedeskMessenger | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [messageCount, setCount] = useState(0);
  const [state, updateState] = useState<'idle' | 'loading' | 'loaded'>('idle');
  const [isReady, updateReadyStatus] = useState<boolean>(true);

  useEffect(() => {
    window.__usedeskWidgetMessageInClosedWidgetCallback = () => {
      if (optionRef.current?.isFirstMessageSkipped) {
        setCount((value) => value + 1);
      } else {
        optionRef.current = { ...optionRef.current, isFirstMessageSkipped: true };
      }
    };

    return () => {
      window.__usedeskWidgetMessageInClosedWidgetCallback = () => null;
    };
  }, []);

  const [Loader] = useState(() =>
    createWidgetLoader(
      (widgetInstance) => {
        updateState('loaded');
        setWidget(widgetInstance);
        if (optionRef.current?.openWhenReady) {
          widgetInstance.open(optionRef.current.openWhenReady);
          updateReadyStatus(!optionRef.current?.isInitializationRequired);
          setCount(0);
          optionRef.current.openWhenReady = undefined;
        }
      },
      (error) => {
        setError(error);
        updateReadyStatus(true);
      }
    )
  );

  useEffect(() => {
    window.__usedeskWidgetInitedCallback = () => {
      optionRef.current = { ...optionRef.current, isInitializationRequired: false };
      updateReadyStatus(true);
    };

    return () => {
      window.__usedeskWidgetInitedCallback = () => null;
    };
  }, []);

  const MountPoint = useMemo(() => (state === 'idle' ? NullComponent : Loader), [Loader, state]);

  const value = useMemo(
    () => ({
      error,
      messageCount,
      isReady,
      open: (type = 'chat') => {
        if (error) return;

        if (widget && state === 'loaded') {
          if (optionRef.current?.isInitializationRequired) {
            updateReadyStatus(false);
          }
          setCount(0);
          widget.open(type);
        } else {
          updateReadyStatus(false);
          optionRef.current = { ...optionRef.current, openWhenReady: type };
        }
      },
      load: () => {
        if (state === 'idle') {
          updateState('loading');
        }
      },
    }),
    [error, isReady, messageCount, state, widget]
  );

  return (
    <context.Provider value={value}>
      {children}
      <MountPoint />
    </context.Provider>
  );
}

export const useUseDesk = () => {
  const useDesk = useContext(context);

  if (!useDesk) {
    throw Error('`useUseDesk` must be used inside a `UseDeskProvider`');
  }

  return useDesk;
};
