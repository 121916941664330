import { useEffect, useState } from 'react';
import useEvent from 'use-event-callback';

function loadScript(src: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.onload = () => resolve();
    script.onerror = reject;
    script.type = 'text/javascript';
    script.src = src;

    document.body.appendChild(script);
  });
}

const onErrorDefault = (error: Error) => {};
export const createLoader = <T>(src: string, extractor: () => T) => {
  let promise: Promise<void> | undefined;
  let isLoaded = false;

  const useLoader = (onError = onErrorDefault): T | undefined => {
    const [global, setGlobal] = useState<T | undefined>(() => {
      if (isLoaded) {
        return extractor();
      }

      return undefined;
    });

    const onErrorRef = useEvent(onError);

    useEffect(() => {
      if (global !== undefined) return;

      let isMounted = true;

      function unmount() {
        isMounted = false;
      }

      if (promise) {
        promise.then(() => isMounted && setGlobal(() => extractor()), onErrorRef);

        return unmount;
      }

      promise = loadScript(src);
      promise.then(() => {
        isLoaded = true;
        isMounted && setGlobal(() => extractor());
      }, onErrorRef);

      return unmount;
    }, [global, onErrorRef]);

    return global;
  };

  return useLoader;
};
