import wrapWithProvider from './wrap-with-provider';
import { event } from './src/analytics/gtm';

import './src/styles/index.scss';

export const wrapRootElement = wrapWithProvider;

const siteBranchName = process.env.BRANCH;

const sendCurrentBranchEvent = () => {
  event('event_track_branch', {
    branch: `site_${siteBranchName}`,
  });
};

const scroll = () => {
  document.body.scrollTop = 0;
};

const initScripts = () => {
  sendCurrentBranchEvent();
};

export const shouldUpdateScroll = scroll;
export const onClientEntry = initScripts;
