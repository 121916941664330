import { nanoid } from 'nanoid';
import React, { useEffect, useState, type ReactNode } from 'react';
import useConstant from 'use-constant';
import useEvent from 'use-event-callback';

import { createLoader } from './hooks';

export { createLoader };

// sdk-suggest hook

type YandexLoginData = {
  access_token: string;
  expires_in: string;
  token_type: string;
};

type ButtonSize = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
type ButtonTheme = 'light' | 'dark';
type ButtonIcon = 'ya' | 'yaEng';

type SuggestParamsForBGIcon = {
  view: 'button';
  parentId?: string;
  buttonView: 'iconBg';
  buttonTheme?: ButtonTheme;
  buttonSize?: ButtonSize;
  buttonBorderRadius?: number;
  buttonIcon?: ButtonIcon;

  customBgColor?: string;
  customBgHoveredColor?: string;
  customBorderColor?: string;
  customBorderHoveredColor?: string;
  customBorderWidth?: string;
};

type SuggestParams = {
  view: 'button';
  parentId?: string;
  buttonView?: 'main' | 'additional' | 'icon';
  buttonTheme?: ButtonTheme;
  buttonSize?: ButtonSize;
  buttonBorderRadius?: number;
  buttonIcon?: ButtonIcon;
};

type OAuthQueryParams = { client_id: string; response_type: string; redirect_uri?: string };

declare global {
  interface Window {
    YaAuthSuggest: {
      init: (
        oauthQueryParams: OAuthQueryParams,
        tokenPageOrigin: string,
        suggestParams?: SuggestParamsForBGIcon | SuggestParams
      ) => Promise<
        | { status: 'ok'; handler: () => Promise<YandexLoginData> }
        | { status: 'error'; code: string }
      >;
    };
  }
}

export const useSdkSuggest = createLoader(
  'https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-latest.js',
  () => window.YaAuthSuggest
);

// sdk-suggest-token hook

declare global {
  interface Window {
    YaSendSuggestToken: (origin: string, extraData?: any) => void;
  }
}

export const useSdkSuggestToken = createLoader(
  'https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-token-latest.js',
  () => window.YaSendSuggestToken
);

// button component

let isLocked = false;
const queue: (() => void)[] = [];

const Locker = {
  lock: () =>
    new Promise<void>((resolve) => {
      if (isLocked) {
        queue.push(() => resolve());
      } else {
        isLocked = true;
        setTimeout(resolve);
      }
    }),
  unlock: () => {
    if (queue.length) {
      const next = queue.shift();
      next && next();
    } else {
      isLocked = false;
    }
  },
};

function Loader({ size = 50 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 50 50"
    >
      <path
        fill="currentColor"
        d="M43.935 25.145c0-10.318-8.364-18.683-18.683-18.683-10.318 0-18.683 8.365-18.683 18.683h4.068c0-8.071 6.543-14.615 14.615-14.615s14.615 6.543 14.615 14.615h4.068z"
      >
        <animateTransform
          attributeName="transform"
          attributeType="xml"
          dur="0.4s"
          from="0 25 25"
          repeatCount="indefinite"
          to="360 25 25"
          type="rotate"
        />
      </path>
    </svg>
  );
}

const sizeInPX = {
  xs: 36,
  s: 40,
  m: 44,
  l: 48,
  xl: 52,
  xxl: 56,
};
const sizeToPX = (size: ButtonSize) => sizeInPX[size];

function Container({ size, children }: { children: ReactNode; size: ButtonSize }) {
  return (
    <div
      style={{
        position: 'relative',
        zIndex: 0,
        height: sizeToPX(size),
        overflow: 'hidden',
      }}
    >
      {children}
    </div>
  );
}

function Base({ borderRadius }: { borderRadius: number }) {
  return (
    <div
      style={{
        position: 'absolute',
        zIndex: -1,
        inset: '0px',
        width: '100%',
        height: '100%',
        borderRadius,
        backgroundColor: 'black',
      }}
    />
  );
}

function LoadingBlock({ size }: { size: ButtonSize }) {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
      }}
    >
      <Loader size={sizeToPX(size) - 12} />
    </div>
  );
}

type YandexAuthButtonProps = {
  oauthQueryParams: OAuthQueryParams;
  pageOrigin: string;
  onLogin: (token: string) => void;
} & Omit<SuggestParamsForBGIcon, 'view' | 'parentId' | 'buttonView'> &
  Omit<SuggestParams, 'view' | 'parentId' | 'buttonView'> &
  (Pick<SuggestParams, 'buttonView'> | Pick<SuggestParamsForBGIcon, 'buttonView'>);

export function YandexAuthButton({
  oauthQueryParams,
  pageOrigin,
  onLogin,
  buttonView = 'main',
  buttonTheme = 'light',
  buttonSize = 'm',
  buttonBorderRadius = 0,
  buttonIcon = 'ya',
  customBgColor,
  customBgHoveredColor,
  customBorderColor,
  customBorderHoveredColor,
  customBorderWidth,
}: YandexAuthButtonProps) {
  const [isLoading, updateLoadingState] = useState(true);
  const id = useConstant(() => `yaId_${nanoid(4)}`);
  const yaAuth = useSdkSuggest();

  const onLoginRef = useEvent(onLogin);

  useEffect(() => {
    if (!yaAuth) return;
    let isMounted = true;

    async function init(auth: typeof window.YaAuthSuggest) {
      updateLoadingState(true);
      await Locker.lock();

      // if unmounted we need to release lock for next buttons
      if (!isMounted) return Locker.unlock();

      const button = await auth.init(oauthQueryParams, pageOrigin, {
        view: 'button',
        parentId: id,
        buttonView,
        buttonTheme,
        buttonSize,
        buttonBorderRadius,
        buttonIcon,
        customBgColor,
        customBgHoveredColor,
        customBorderColor,
        customBorderHoveredColor,
        customBorderWidth,
      });

      // if unmounted we need to release lock for next buttons
      if (button.status === 'error' || !isMounted) return Locker.unlock();

      const promise = button.handler();
      Locker.unlock();
      updateLoadingState(false);
      const { access_token: token } = await promise;

      if (!isMounted) return;

      onLoginRef(token);
    }

    init(yaAuth);

    return () => {
      isMounted = false;
    };
  }, [
    buttonBorderRadius,
    buttonIcon,
    buttonSize,
    buttonTheme,
    buttonView,
    customBgColor,
    customBgHoveredColor,
    customBorderColor,
    customBorderHoveredColor,
    customBorderWidth,
    id,
    oauthQueryParams,
    onLoginRef,
    pageOrigin,
    yaAuth,
  ]);

  return (
    <Container size={buttonSize}>
      {isLoading && <LoadingBlock size={buttonSize} />}

      <Base borderRadius={buttonBorderRadius} />

      <div id={id} />
    </Container>
  );
}
