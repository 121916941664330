import React from 'react';
import { UseDeskProvider } from '@periodica/usedesk';
import { AlertProvider } from './src/hooks/useAlertContext';

export default function({ element }) {
  return (
    <UseDeskProvider>
      <AlertProvider>{element}</AlertProvider>
    </UseDeskProvider>
  );
};
